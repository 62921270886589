/**
 * 申怡研学相关接口
 */
import request from '@/request/request'

// 获取日历票接口
export const getTicketList = (params) => request.post(`/adminv2/research/ticket/list`, params);
// 日历票， 上架/下架
export const changeTicktStatus = (params) => request.post('/adminv2/research/ticket/status', params);
// 日历票详情
export const getTicketDetail = (params) => request.post('/adminv2/research/ticket/detail', params);
// 日历票-编辑
export const editTicket = (params) => request.post('/adminv2/research/ticket/edit', params);
// 日历票-添加
export const addTicket = (params) => request.post('/adminv2/research/ticket/add', params);


// 库存列表
export const getStockList = (params) => request.post('/adminv2/research/stock/list', params);
// 添加日历库存
export const addStock = (params) => request.post('/adminv2/research/stock/add', params);
// 编辑日历库存
export const editStock = (params) => request.post('/adminv2/research/stock/edit', params);
// 删除日历库存
export const deleteStock = (params) => request.post('/adminv2/research/stock/delete', params);
// 获取日历库存包含日期
export const getCalendarList = (params) => request.post('/adminv2/research/calendar/list', params);
// 添加日历库存日期
export const addCalendar = (params) => request.post('/adminv2/research/calendar/add', params);
// 编辑日历库存日期
export const editCalendar = (params) => request.post('/adminv2/research/calendar/edit', params);
// 获取场次列表
export const getBatchList = (params) => request.post('/adminv2/research/batch/list', params);
// 编辑场次信息
export const editBatch = (params) => request.post('/adminv2/research/batch/edit', params);
// 编辑场次信息
export const addBatch = (params) => request.post('/adminv2/research/batch/add', params);


// 订单
// 订单列表
export const researchOrderList = (params) => request.post('/adminv2/research/order/live', params);

// 登记信息
export const researchCheckinList = (params) => request.post('/adminv2/research/checkin/list', params);