// 订单状态
export const orderStatusMap = new Map([
  [200, '支付成功'],
  [201, '待使用'],
  [1, '交易完成']
])
// 子订单状态
export const subOrderStatusMap = new Map([
  [20, '支付成功'],
  [100, '待使用'],
  [201, '已预约'],
  [301, '已退款'],
  [400, '履约中'],
  [401, '已履约']
])
// 登记状态
export const checkinStatusMap = new Map([
  [0, '未登记'],
  [1, '已登记'],
  [2, '已取消']
])
// 核销状态
export const verifyStatusMap = new Map([
  [0, '未核销'],
  [1, '已核销']
])
// 订单列表
export const orderListColumns = [
  {
    title: '订单编号',
    dataIndex: 'orderId',
    key: 'orderId',
    width: 280
  },
  {
    title: '商品名称',
    dataIndex: 'productName',
    key: 'productName',
    width: 500
  },
  // {
  //   title: '商品类型',
  //   dataIndex: 'businessType',
  //   key: 'businessType',
  // },
  {
    title: '用户手机号',
    dataIndex: 'phone',
    key: 'phone',
    width: 180
  },
  {
    title: '支付金额',
    dataIndex: 'receiptAmount',
    key: 'receiptAmount',
    width: 100,
    customRender: (text) => {
      return (text / 100) + '元'
    },
  },
  {
    title: '订单状态',
    dataIndex: 'orderStatus',
    key: 'orderStatus',
    customRender: (text, record) => {
      if (+text === 1 && +record.subOrderStatus === 301) {
        return '已退款'
      } else if (+text === 200 || +text === 201) {
        return '已支付'
      }
    },
    width: 100
  },
  // {
  //   title: '购买数量',
  //   dataIndex: 'numbers',
  //   key: 'numbers',
  //   width: 100
  // },
  {
    title: '登记状态',
    dataIndex: 'checkinStatus',
    key: 'checkinStatus',
    customRender: (text) => {
      return checkinStatusMap.get(text)
    },
    width: 100
  },
  // {
  //   title: '下单时间',
  //   dataIndex: 'createTime',
  //   key: 'createTime'
  // },
  {
    title: '支付时间',
    dataIndex: 'payTime',
    key: 'payTime',
    customRender: (text) => {
      return text === null ? '——' : text
    },
    width: 180
  }, 
  {
    title: '核销时间',
    dataIndex: 'verifyDate',
    key: 'verifyDate',
    customRender: (text) => {
      return text === null ? '——' : text
    },
    width: 180
  }, 
  // {
  //   title: '操作',
  //   dataIndex: 'operation',
  //   scopedSlots: { customRender: 'operation' },
  //   width: 200,
  //   fixed: 'right'
  // }
]
// 登记状态
// 未登记、已登记、已取消
export const checkInStatusArr = [
  {
    key: 0,
    value: '全部状态'
  },
  {
    key: 1,
    value: '未登记'
  },
  {
    key: 2,
    value: '已登记'
  },
  {
    key: 3,
    value: '已取消'
  }
]
// 订单状态：1已支付，2已退款
export const orderStatusArr = [
  {
    key: 0,
    value: '全部状态'
  },
  {
    key: 1,
    value: '已支付'
  },
  {
    key: 2,
    value: '已退款'
  }
]
// 核销状态：1未核销，2已核销
export const verifyStatusArr = [
  {
    key: 0,
    value: '全部状态'
  },
  {
    key: 1,
    value: '未核销'
  },
  {
    key: 2,
    value: '已核销'
  }
]


// 登记列表字段
export const registerListColumns = [
  {
    title: '订单编号',
    dataIndex: 'orderId',
    key: 'orderId',
    width: 180
  },
  {
    title: '券码',
    dataIndex: 'certificateId',
    key: 'certificateId',
    width: 180
  },
  {
    title: '商品名称',
    dataIndex: 'ticketTitle',
    key: 'ticketTitle',
    width: 300
  },
  {
    title: '登记状态',
    dataIndex: 'checkinStatus',
    key: 'checkinStatus',
    customRender: (text) => {
      return checkinStatusMap.get(text)
    },
    width: 100
  },
  {
    title: '出行日期',
    dataIndex: 'calendarDate',
    key: 'calendarDate',
    width: 180
  },
  {
    title: '出行场次',
    dataIndex: 'batchTitle',
    key: 'batchTitle',
    width: 150
  },
  {
    title: '下单时间',
    dataIndex: 'createTime',
    key: 'createTime',
    width: 180
  },
  {
    title: '出行人数',
    dataIndex: 'numbers',
    key: 'numbers',
    width: 100,
    customRender: (text, record) => {
      const contacts = record.contacts
      return contacts ? contacts.length : ''
    },
  },
  {
    title: '联系人手机号',
    dataIndex: 'phone',
    key: 'phone',
    width: 180
  },
  {
    title: '紧急人手机号',
    dataIndex: 'urgentPhone',
    key: 'urgentPhone',
    width: 180
  },
  {
    title: '出行人信息',
    dataIndex: 'contacts',
    key: 'contacts',
    width: 220,
    scopedSlots: { customRender: 'contacts' },
  }, 
  // {
  //   title: '操作',
  //   dataIndex: 'operation',
  //   scopedSlots: { customRender: 'operation' },
  // }
]

// 日历票列表
export const ticketListColumns = [
  {
    title: '日历票名称',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '关联商品ID',
    dataIndex: 'productId',
    key: 'productId',
  },
  {
    title: '关联库存名称',
    dataIndex: 'stockTitle',
    key: 'stockTitle',
  },
  {
    title: '出行前核销天数',
    dataIndex: 'advanceDays',
    key: 'advanceDays',
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    customRender: (text) => {
      // 1已上架，0未上架
      return +text ? '已上架' : '未上架'
    }
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  }
]

// 日期库存列表
export const stockListColumns = [
  {
    title: '日期库存名称',
    dataIndex: 'stockTitle',
    key: 'stockTitle',
  },
  // {
  //   title: '关联日历票',
  //   dataIndex: 'businessName',
  //   key: 'businessName',
  // },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  }
]